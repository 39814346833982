import React from "react"
import styled, { css } from "styled-components"
import { colors, easings, resetLink } from "./Style"
import { Link as GatsbyLink } from "gatsby"
import Icon from "./Icon"

const StyledIcon = styled(Icon)`
  position: relative;
  top: 0.5rem;
  margin-left: 0.6rem;
`

const StyledLink = styled.a`
  ${resetLink};
  position: relative;
  padding-bottom: 0.3rem;
  color: ${props => (props.dark ? colors.complementary.blue : colors.yellow)};
  font-size: 2rem;
  font-weight: 700;
  transition-property: color, box-shadow;
  transition-duration: 0.2s;
  transition-timing-function: ${easings.appleOut};
  box-shadow: inset 0 0 0 transparent;

  ${props =>
    props.as === "button"
      ? css`
          display: inline-block;
          padding: 0 0 0.5rem;
          border: none;
          cursor: pointer;
          background-color: transparent;

          &:focus {
            box-shadow: none;
            outline: none;
          }
        `
      : ""}

  ${props =>
    !props.noState
      ? css`
          &:hover {
            color: ${props => (props.dark ? colors.blue : colors.yellow)};
          }

          &:focus {
            color: ${props => (props.dark ? colors.blue : colors.white)};
          }

          &:hover,
          &:focus {
            box-shadow: inset 0 -0.2rem 0 ${props => (props.dark ? colors.blue : colors.yellow)};
          }
        `
      : ""}
`

export default function Link({
  children,
  to,
  external = false,
  dark = false,
  icon = false,
  as,
  ...props
}) {
  const handleLinkAttributes = () => {
    let baseAttr = {}

    switch (as) {
      case "button":
        baseAttr = {
          as: "button",
        }
        break

      default:
        baseAttr = external
          ? {
              as: as,
              href: to,
              target: "_blank",
              rel: "noopener noreferrer",
            }
          : {
              as: GatsbyLink,
              to,
            }
        break
    }

    const colorAttr = {
      dark: dark ? 1 : 0,
    }

    return {
      ...baseAttr,
      ...colorAttr,
    }
  }

  const iconAttributes = () => {
    return {
      icon: ["far", "long-arrow-right"],
      width: 23,
      height: 23,
    }
  }

  return (
    <StyledLink {...handleLinkAttributes()} {...props}>
      {children}
      {icon && <StyledIcon {...iconAttributes()} />}
    </StyledLink>
  )
}

export function NoStateLink({ children, dark, icon, ...props }) {
  const iconAttributes = () => {
    return {
      icon: ["far", "long-arrow-right"],
      width: 23,
      height: 23,
    }
  }

  return (
    <StyledLink noState={true} dark={dark ? 1 : 0} {...props}>
      {children}
      {icon && <StyledIcon {...iconAttributes()} />}
    </StyledLink>
  )
}
