import styled from "styled-components"
import { colors, sidePadding } from "../Style"
import { Preamble } from "../Text"

export const StyledContainer = styled.section`
  max-width: 75rem;
  padding: 20.7rem ${sidePadding}rem 25.3rem;
  margin: auto;
  text-align: center;
`

export const Title = styled.h2`
  margin-bottom: 1.8rem;
  color: ${colors.yellow};
  font-size: 3.2rem;
  font-weight: 700;
  letter-spacing: 0.5rem;
  text-transform: uppercase;
`

export const Text = styled(Preamble)`
  margin-bottom: 2.7rem;
`
