const inputValues = {
  review: "",
  rating: 0,
  term: "",
  year: "",
  description: "",
  name: "",
  company: "",
  machineName: "",
  email: "",
  city: "",
  file: "",
  accept: false,
  otherUseCase: "",
}

export const initialForm = {
  form: {
    step: 1,
    length: 3,
    submit: false,
    errors: {},
    form: null,
    values: {
      model: "",
      models: [],
      importantAttribute: "",
      attributes: [],
      useCase: "",
      useCases: [],
      ...inputValues,
    },
  },
}

export function formReducer(state, action) {
  switch (action.type) {
    case "FORM_SET":
      return {
        ...state,
        ...action.payload,
      }
    case "FORM_NEXT_STEP":
      return {
        ...state,
        step: state.step + 1,
      }
    case "FORM_PREVIOUS_STEP":
      return {
        ...state,
        step: state.step - 1,
      }
    case "FORM_SET_STEP":
      return {
        ...state,
        step: action.payload,
      }
    case "FORM_SUBMIT":
      return {
        ...state,
        errors: {},
        submit: true,
      }

    case "FORM_ERROR_SET":
      return {
        ...state,
        submit: false,
        errors: action.payload,
        step: 1,
        values: {
          ...state.values,
          file: "",
        },
      }

    case "FORM_ERROR_UPDATE":
      return {
        ...state,
        errors: action.payload,
      }

    case "FORM_UPDATE":
      return {
        ...state,
        values: {
          ...state.values,
          [action.payload.target]: action.payload.value,
        },
      }

    case "FORM_RESET":
      return {
        ...state,
        step: 1,
        submit: false,
        errors: {},
        values: {
          ...state.values,
          ...inputValues,
        },
      }

    default:
      return state
  }
}
