import { useEffect } from "react"
import { useStateValue } from "../state"

export default pageContext => {
  const [state, dispatch] = useStateValue()
  const { models: m, attributes: a, useCases: u } = state.form.values

  const sortedModels = pageContext.models ? pageContext.models.sort((a, b) =>
    a.title.localeCompare(b.title)
  ) : [{}]
  const sortedAttributes = pageContext.attributes ? pageContext.attributes.sort((a, b) =>
    a.name.localeCompare(b.name)
  ) : [{}]
  const sortedUseCases = pageContext.useCases ? pageContext.useCases.sort((a, b) =>
    a.name.localeCompare(b.name)
  ) : [{}]

  useEffect(() => {
    if (m.length < 1) {
      dispatch({
        type: "FORM_UPDATE",
        payload: {
          target: "models",
          value: sortedModels.map(model => ({
            name: model.title,
            value: model.id,
          })),
        },
      })

      dispatch({
        type: "FORM_UPDATE",
        payload: {
          target: "model",
          value: sortedModels[0].id,
        },
      })
    }

    if (a.length < 1) {
      dispatch({
        type: "FORM_UPDATE",
        payload: {
          target: "attributes",
          value: sortedAttributes.map(model => ({
            name: model.name,
            value: model.id,
          })),
        },
      })

      dispatch({
        type: "FORM_UPDATE",
        payload: {
          target: "importantAttribute",
          value: sortedAttributes[0].id,
        },
      })
    }

    if (u.length < 1) {
      dispatch({
        type: "FORM_UPDATE",
        payload: {
          target: "useCases",
          value: sortedUseCases.map(model => ({
            name: model.name,
            value: model.id,
          })),
        },
      })

      dispatch({
        type: "FORM_UPDATE",
        payload: {
          target: "useCase",
          value: sortedUseCases[0].id,
        },
      })
    }
  }, [dispatch, sortedModels, sortedAttributes, sortedUseCases, m, a, u])
}
