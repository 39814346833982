import React, { createContext, useContext, useReducer } from "react"
import {
  initialMenu,
  initialForm,
  initialAbout,
  initialLike,
  initialEdit,
} from "../reducers/"

export const initialState = {
  ...initialMenu,
  ...initialForm,
  ...initialAbout,
  ...initialLike,
  ...initialEdit,
}

export const StateContext = createContext([initialState, () => {}])

export const StateProvider = ({ reducer, initialState, children }) => (
  <StateContext.Provider value={useReducer(reducer, initialState)}>
    {children}
  </StateContext.Provider>
)

export const useStateValue = () => useContext(StateContext)
