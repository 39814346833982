export const initialAbout = {
  about: { isOpen: false, seen: false },
}

export function aboutReducer(state, action) {
  switch (action.type) {
    case "ABOUT_OPEN":
      return {
        ...state,
        isOpen: true,
      }
    case "ABOUT_CLOSE":
      return {
        ...state,
        isOpen: false,
      }
    case "ABOUT_TOGGLE":
      return {
        ...state,
        isOpen: !state.isOpen,
      }
    case "ABOUT_SEEN":
      return {
        ...state,
        seen: true,
      }
    default:
      return state
  }
}
