import { config, library } from "@fortawesome/fontawesome-svg-core"
import {
  faArrowRight,
  faLongArrowRight,
  faPlusHexagon,
  faFlagCheckered,
  faTimesHexagon,
  faExchange,
} from "@fortawesome/pro-regular-svg-icons"
import {
  faThumbsUp,
  faPlayCircle,
  faShare,
} from "@fortawesome/pro-light-svg-icons"
import {
  faStar,
  faChevronDown,
  faImage,
} from "@fortawesome/pro-solid-svg-icons"

config.autoAddCss = false
library.add(
  faArrowRight,
  faLongArrowRight,
  faThumbsUp,
  faStar,
  faPlusHexagon,
  faPlayCircle,
  faChevronDown,
  faImage,
  faShare,
  faFlagCheckered,
  faExchange,
  faTimesHexagon
)
