import React from "react"
import { hasKey } from "../../utils/"
import { StyledButton, StyledIcon, StyledSlimButton } from "./Style"

export default function Button({ children, icon, ...props }) {
  const iconAttributes = () => {
    return {
      ...icon,
      icon: hasKey(icon, "name") ? icon.name : ["far", "long-arrow-right"],
      width: hasKey(icon, "width") ? icon.width : 23,
      height: hasKey(icon, "height") ? icon.height : 23,
    }
  }

  return (
    <StyledButton {...props}>
      {hasKey(icon, "position")
        ? icon.position === "left" && <StyledIcon {...iconAttributes()} />
        : null}
      {children}
      {hasKey(icon, "position")
        ? icon.position !== "left" && <StyledIcon {...iconAttributes()} />
        : null}
    </StyledButton>
  )
}

export function ButtonSlim({ icon, children, ...props }) {
  return (
    <StyledSlimButton {...props}>
      <StyledIcon icon={icon} width={20} height={20} />
      {children}
    </StyledSlimButton>
  )
}

export function LikeButton({ children, ...props }) {
  return (
    <ButtonSlim icon={["fal", "thumbs-up"]} {...props}>
      Gilla
    </ButtonSlim>
  )
}
