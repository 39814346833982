import React from "react"
import { Link as GatsbyLink } from "gatsby"
import { useStateValue } from "../../state"
import useNav from "../../lib/useNav"
import { Container, Content, Item, Link, StyledIcon } from "./Style"

export default function Menu({ location }) {
  const [state, dispatch] = useStateValue()
  const [items] = useNav()

  const handleAttributes = item => {
    const isAbout = item.name.toLowerCase() === "om sidan"

    return isAbout
      ? {
          "aria-haspopup": "true",
          as: "button",
          tabIndex: "0",
          onClick: () => {
            dispatch({ type: "ABOUT_OPEN" })
            dispatch({
              type: "MENU_CLOSE",
            })
          },
        }
      : {
          as: GatsbyLink,
          to: item.href,
          onClick: () =>
            dispatch({
              type: "MENU_CLOSE",
            }),
        }
  }

  return (
    <Container id="Nav" aria-hidden={state.menu.isOpen}>
      <Content>
        {items.map((item, index) => (
          <Item key={index}>
            <Link {...handleAttributes(item)}>
              {item.icon.display && (
                <StyledIcon icon={item.icon.name} width="25" />
              )}
              {item.name}
            </Link>
          </Item>
        ))}
      </Content>
    </Container>
  )
}
