import styled, { css } from "styled-components"
import Icon from "../Icon"
import { hexToRgb } from "../../utils/"
import { colors, zIndex, sidePadding, resetLink } from "../Style"

export const Container = styled.nav`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  padding: ${sidePadding}rem;
  z-index: ${zIndex.menu};
  background-color: rgba(${hexToRgb(colors.blue).rgb}, 0.2);
`

export const Content = styled.ul`
  flex: 1;
  padding: 16.2rem 4.8rem 4.8rem;
  overflow-y: scroll;
  justify-content: center;
  background-color: ${colors.yellow};
`

export const Item = styled.li`
  &:not(:last-child) {
    margin-bottom: 4.8rem;
  }
`

export const Link = styled.a`
  ${resetLink}
  position: relative;
  font-size: 2.4rem;
  font-weight: 700;
  color: ${colors.complementary.blue};

  &:hover {
    color: #424b52;
  }

  &:focus {
    color: ${colors.blue};
  }

  ${props =>
    props.as === "button"
      ? css`
          display: inline-block;
          padding: 0;
          border: none;
          cursor: pointer;
          background-color: transparent;

          &:focus {
            box-shadow: none;
            outline: none;
          }
        `
      : ""}
`

export const StyledIcon = styled(Icon)`
  position: absolute;
  left: -${2.5 + 0.8}rem;
`
