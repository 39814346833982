import React from "react"
import useMobile from "../../lib/useMobile"
import HeaderMobile from "./mobile"
import HeaderDekstop from "./desktop"

export default function Header({ location }) {
  const [isMobile] = useMobile()

  return isMobile ? <HeaderMobile location={location} /> : <HeaderDekstop />
}
