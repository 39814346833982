import React from "react"
import { useStaticQuery, graphql } from "gatsby"

export default function Epiroc({ ...props }) {
  const data = useStaticQuery(graphql`
    query Logo {
      file(relativePath: { eq: "Logo.svg" }) {
        id
        publicURL
      }
    }
  `)

  return <img src={data.file.publicURL} alt="Logotyp Epiroc" {...props} />
}
