import styled from "styled-components"
import { colors, sidePadding, zIndex, breakpoints } from "../../Style"
import Bg from "../../../assets/nav_bg.jpg"
import Line from "../../../assets/nav_line.jpg"
import Rust from "../../../assets/nav_rust1.png"

export const Texture = styled.div`
  background-color: ${colors.blue};
  background-image: url(${Bg});

  &,
  &::before,
  &::after {
    background-repeat: repeat-x;
    background-position: center;
    background-size: contain;
  }

  // line + rust
  &::before,
  &::after {
    position: absolute;
    left: 0;
    right: 0;
    display: block;
    width: 100%;
    content: "";
  }

  // rust
  &::before {
    top: 0;
    bottom: 0.7rem;
    background-image: url(${Rust});
  }

  // line
  &::after {
    bottom: 0;
    height: 0.7rem;
    background-image: url(${Line});
    background-color: ${colors.black};
  }
`

export const Container = styled(Texture)`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: ${zIndex.header};
  display: grid;
  grid-template-columns: 1fr 56rem 1fr;
  grid-template-rows: 1fr;
  grid-column-gap: 1rem;
  padding: 4.2rem ${sidePadding * 2}rem 3.9rem;
`

export const Middle = styled.div`
  display: flex;
  grid-area: 1 / 2 / 2 / 3;
`

export const Right = styled.div`
  display: flex;
  justify-content: flex-end;
  grid-area: 1 / 3 / 2 / 4;

  @media ${breakpoints.large} {
    padding-right: 2rem;
  }
`

export const StyledTVA = styled.img`
  position: relative;
  z-index: 1;
  width: 56rem;
  height: 4rem;
`

export const StyledEpiroc = styled.img`
  position: relative;
  z-index: 1;
  width: 13rem;
`

export const Link = styled.a`
  &:focus {
    outline: none;
  }

  &:focus:not(:hover) {
    outline: 0.2rem solid ${colors.yellow};
  }
`
