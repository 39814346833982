import React from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

export default function Icon({
  icon,
  name,
  width,
  height,
  className,
  ...props
}) {
  return (
    <FontAwesomeIcon
      className={className}
      icon={icon}
      width={width}
      height={height}
      {...props}
    />
  )
}
