import React from "react"
import { useStateValue } from "../../state"
import useNav from "../../lib/useNav"
import Link from "../Link"
import { Nav, List, Item, StyledIcon } from "./Style"

export default function Navigation() {
  const [items] = useNav()
  const [, dispatch] = useStateValue()

  const handleAttributes = item => {
    const isAbout = item.name.toLowerCase() === "om sidan"

    return isAbout
      ? {
          "aria-haspopup": "true",
          as: "button",
          tabIndex: "0",
          onClick: () => dispatch({ type: "ABOUT_OPEN" }),
        }
      : {
          to: item.href,
        }
  }

  return (
    <Nav>
      <List>
        {items.map((item, index) => (
          <Item key={index}>
            <Link {...handleAttributes(item)}>
              {item.icon.display && <StyledIcon icon={item.icon.name} />}
              {item.name}
            </Link>
          </Item>
        ))}
      </List>
    </Nav>
  )
}
