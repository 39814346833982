import React, { useEffect, useState } from "react"

export default function Padder({ element, initial = 0 }) {
  const [height, setHeight] = useState(initial)

  useEffect(() => {
    const handleResize = () => {
      setHeight(`${element.current.getBoundingClientRect().height / 10}rem`)
    }

    handleResize()
    window.addEventListener("resize", handleResize)

    return () => {
      window.removeEventListener("resize", handleResize)
    }
  }, [element])

  return <div style={{ height: height }} />
}
