// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-promo-js": () => import("./../src/templates/promo.js" /* webpackChunkName: "component---src-templates-promo-js" */),
  "component---src-templates-case-js": () => import("./../src/templates/case.js" /* webpackChunkName: "component---src-templates-case-js" */),
  "component---src-templates-post-js": () => import("./../src/templates/post.js" /* webpackChunkName: "component---src-templates-post-js" */),
  "component---src-templates-edit-js": () => import("./../src/templates/edit.js" /* webpackChunkName: "component---src-templates-edit-js" */),
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-add-machine-js": () => import("./../src/pages/addMachine.js" /* webpackChunkName: "component---src-pages-add-machine-js" */),
  "component---src-pages-cases-js": () => import("./../src/pages/cases.js" /* webpackChunkName: "component---src-pages-cases-js" */),
  "component---src-pages-faq-js": () => import("./../src/pages/faq.js" /* webpackChunkName: "component---src-pages-faq-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-namegenerator-js": () => import("./../src/pages/namegenerator.js" /* webpackChunkName: "component---src-pages-namegenerator-js" */),
  "component---src-pages-success-js": () => import("./../src/pages/success.js" /* webpackChunkName: "component---src-pages-success-js" */)
}

