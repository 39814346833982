import styled from "styled-components"
import { colors, sidePadding } from "../../Style"

export const Container = styled.div`
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-height: 6rem;
  padding: ${sidePadding * 0.5}rem 1.4rem ${sidePadding * 0.5}rem
    ${sidePadding * 0.5}rem;
  background-color: ${colors.blue};
`

export const StyledLogo = styled.img`
  margin: 0;
  width: 8.8rem;
`

export const DotsContainer = styled.div`
  margin-left: ${sidePadding}rem;
`
