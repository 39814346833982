import React from "react"
import { useSpring } from "react-spring"
import { useStateValue } from "../../state"
import { Box, Line, LineTop, LineBottom } from "./Style"

export default function Hamburger() {
  const [state, dispatch] = useStateValue()

  const props = useSpring({
    o: state.menu.isOpen ? 0 : 1,
    y: state.menu.isOpen ? 0 : 1,
    r: state.menu.isOpen ? 1 : 0,
    config: { mass: 1, tension: 0, friction: 0 },
  })

  return (
    <Box
      onClick={() =>
        dispatch({
          type: "MENU_TOGGLE",
        })
      }
      active={state.menu.isOpen ? 1 : 0}
      aria-label="Öppna menyn"
      aria-controls="Nav"
      aria-haspopup="true"
      aria-expanded={state.menu.isOpen}
    >
      <LineTop
        style={{
          marginTop: props.y.interpolate(y => `-${0.9 * y}rem`),
          transform: props.r.interpolate(
            r => `translate(-50%, -50%) rotate(-${r * 45}deg)`
          ),
        }}
        active={state.menu.isOpen ? 1 : 0}
      />
      <Line
        style={{
          opacity: props.o.interpolate(o => o),
        }}
        active={state.menu.isOpen ? 1 : 0}
      />
      <LineBottom
        style={{
          marginTop: props.y.interpolate(y => `${0.9 * y}rem`),
          transform: props.r.interpolate(
            r => `translate(-50%, -50%) rotate(${r * 45}deg)`
          ),
        }}
        active={state.menu.isOpen ? 1 : 0}
      />
    </Box>
  )
}
