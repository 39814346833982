import React from "react"
import { useStateValue } from "../state/"
import PropTypes from "prop-types"
import Dot from "./dot/"

export default function Dots({
  name = "form-step",
  onChange = () => {},
  preventOnChange = true,
  ...props
}) {
  const [state, dispatch] = useStateValue()
  const { step, length } = state.form

  return (
    <>
      {Array.apply(null, { length }).map((e, i) => (
        <Dot
          key={"dot-" + i}
          name={name}
          isChecked={i === step - 1}
          title={`Steg ${i + 1}`}
          aria-label={`Hoppa till steget ${i + 1}`}
          onChange={() => {
            onChange()
            if (!preventOnChange) {
              dispatch({ type: "FORM_SET_STEP", payload: i + 1 })
            }
          }}
          {...props}
        />
      ))}
    </>
  )
}

Dots.propTypes = {
  active: PropTypes.number,
  onClick: PropTypes.func,
}
