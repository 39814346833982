import styled from "styled-components"
import { colors, sidePadding, zIndex } from "../../Style"
import { Texture } from "../desktop/Style"

export const Container = styled(Texture)`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: ${zIndex.header};
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: ${sidePadding}rem;

  // line
  &::after {
    height: 0.4rem;
  }
`

export const Link = styled.a`
  &:focus {
    outline: none;
  }

  &:focus:not(:hover) {
    outline: 0.2rem solid ${colors.yellow};
  }
`

export const Logo = styled.img`
  position: relative;
  z-index: 1;
  width: 17.2rem;
  height: 4.8rem;
`
