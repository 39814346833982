import React, { useRef } from "react"
import { Container, Link, Logo } from "./Style"
import { Link as GatsbyLink } from "gatsby"
import { useStateValue } from "../../../state"
import TAV from "../../../lib/useTAV"
import Hamburger from "../../hamburger/"
import Padder from "../../../lib/usePadder"
import Menu from "../../menu/"

export default function HeaderMobile({ location }) {
  const mount = useRef(null)
  const [state] = useStateValue()

  return (
    <>
      <Padder element={mount} initial={"8rem"} />
      <Container ref={mount}>
        <Link as={GatsbyLink} to="/">
          <Logo as={TAV} mobile />
        </Link>
        <Hamburger />

        {state.menu.isOpen && <Menu location={location} />}
      </Container>
    </>
  )
}
