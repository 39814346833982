import React, { useRef } from "react"
import { Link } from "gatsby"
import { useStateValue } from "../../../state"
import Padder from "../../../lib/usePadder"
import Epiroc from "../../../lib/useLogo"
import { Container, StyledLogo } from "./Style"
import Button from "../../button"
import FormActions from "../../form/actions"

export default function FooterMobile({ location }) {
  const mount = useRef(null)
  const [state, dispatch] = useStateValue()
  const { submit, form } = state.edit
  const { pathname } = location

  const handleContent = () => {
    if (pathname.includes("addMachine")) {
      return <FormActions />
    } else if (pathname.includes("edit")) {
      return (
        <>
          <span></span>
          <Button
            type="submit"
            disabled={submit}
            icon={{
              name: ["far", "arrow-right"],
              position: "right",
            }}
            onClick={e => {
              const isValid = form.reportValidity()

              if (isValid) {
                e.preventDefault()
                dispatch({ type: "EDIT_SUBMIT" })
              }
            }}
          >
            {submit ? "Skickar…" : "Skicka"}
          </Button>
        </>
      )
    } else {
      return (
        <>
          <Button
            as={Link}
            to="/addMachine"
            icon={{
              name: ["far", "plus-hexagon"],
              position: "left",
            }}
          >
            Lägg till maskin
          </Button>

          <StyledLogo as={Epiroc} />
        </>
      )
    }
  }

  return (
    <>
      <Padder element={mount} />
      <Container ref={mount}>{handleContent()}</Container>
    </>
  )
}
