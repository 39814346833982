/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

import React from "react"
import { StateProvider, initialState } from "./src/state/"
import {
  menuReducer,
  formReducer,
  aboutReducer,
  likeReducer,
  editReducer,
} from "./src/reducers/"
import "./src/lib/iconLibrary"
import Layout from "./src/components/layout"
import { GlobalStyle } from "./src/components/Style"
import "./src/fix.css"

export const wrapRootElement = ({ element }) => {
  // main reducer
  const mainReducer = ({ menu, form, about, like, edit }, action) => ({
    menu: menuReducer(menu, action),
    form: formReducer(form, action),
    about: aboutReducer(about, action),
    like: likeReducer(like, action),
    edit: editReducer(edit, action),
  })

  return (
    <StateProvider initialState={initialState} reducer={mainReducer}>
      {element}
    </StateProvider>
  )
}

export const wrapPageElement = ({ element, props }) => {
  return (
    <>
      <GlobalStyle />
      <Layout {...props}>{element}</Layout>
    </>
  )
}
