import React, { useRef } from "react"
import {
  Container,
  Middle,
  Right,
  StyledTVA,
  StyledEpiroc,
  Link,
} from "./Style"
import { Link as GatsbyLink } from "gatsby"
import Epiroc from "../../../lib/useLogo"
import TAV from "../../../lib/useTAV"
import Navigation from "../../navigation/"
import Padder from "../../../lib/usePadder"

export default function HeaderDesktop() {
  const mount = useRef()

  return (
    <>
      <Padder element={mount} initial="12.1rem" />
      <Container ref={mount}>
        <Middle>
          <Link to="/" as={GatsbyLink}>
            <StyledTVA as={TAV} />
          </Link>
        </Middle>
        <Right>
          <StyledEpiroc as={Epiroc} />
        </Right>
      </Container>
      <Navigation />
    </>
  )
}
