import React from "react"
import { useStaticQuery, graphql } from "gatsby"

export default function TAV({ mobile = false, ...props }) {
  const data = useStaticQuery(graphql`
    query TAV {
      desktop: file(relativePath: { eq: "TAV.svg" }) {
        id
        publicURL
      }
      mobile: file(relativePath: { eq: "TAV_MOBILE.svg" }) {
        id
        publicURL
      }
    }
  `)

  return (
    <img
      src={data[`${mobile ? "mobile" : "desktop"}`].publicURL}
      alt="Logotyp Testad av verkligheten"
      {...props}
    />
  )
}
