import { useState } from "react"

export default function useNav() {
  const [items, setItems] = useState([
    { name: "Start", href: "/", icon: { display: false, name: "" } },
    {
      name: "Andra berättar",
      href: "/cases",
      icon: { display: false, name: "" },
    },
    {
      name: "Lägg till din maskin",
      href: "/addMachine",
      icon: { display: true, name: ["far", "plus-hexagon"] },
    },
    { name: "Frågor & svar", href: "/faq", icon: { display: false, name: "" } },
  ])

  return [items, setItems]
}
