import React from "react"
import { useStateValue } from "../../../state"
import Dots from "../../Dots"
import Button from "../../button"
import { DotsContainer, Container } from "./Style"

export default function FormActions() {
  const [state, dispatch] = useStateValue()
  const { step, submit, errors, length, form } = state.form

  const handleButtonLabel = () => {
    let label = "Skicka"

    if (step !== length) {
      label = "Gå vidare"
    } else if (submit) {
      label = "Skickar…"
    } else if (Object.keys(errors).length > 0) {
      label = "Försök igen"
    }

    return label
  }

  return (
    <Container>
      <DotsContainer>
        <Dots />
      </DotsContainer>
      <Button
        disabled={submit}
        type="submit"
        icon={{
          name: ["far", "arrow-right"],
          position: "right",
        }}
        onClick={e => {
          const isValid = form.reportValidity()

          if (isValid) {
            e.preventDefault()

            if (step !== length) {
              window.scroll({
                top: form.scrollTop - 90, // - header height ~
                left: 0,
                behavior: "smooth",
              })
              dispatch({ type: "FORM_NEXT_STEP" })
            } else {
              dispatch({ type: "FORM_SUBMIT" })
            }
          }
        }}
      >
        {handleButtonLabel()}
      </Button>
    </Container>
  )
}
