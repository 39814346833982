import styled from "styled-components"
import { animated } from "react-spring"
import { BtnReset } from "../button/Style"
import { sidePadding, colors, zIndex, easings } from "../Style"

export const Box = styled(BtnReset)`
  position: fixed;
  top: ${sidePadding}rem;
  right: ${sidePadding}rem;
  z-index: ${zIndex.hamburger};
  display: block;
  width: 5.2rem;
  height: 5.2rem;
  background-color: ${props =>
    props.active ? colors.gray[700] : colors.yellow};
  transition: background-color 0.2s ${easings.appleOut};
`

export const Line = styled(animated.span)`
  position: absolute;
  display: block;
  top: 50%;
  left: 50%;
  width: 2.8rem;
  height: 0.3rem;
  border-radius: 0.15rem;
  background-color: ${props =>
    props.active ? colors.yellow : colors.complementary.blue};
  transform: translate(-50%, -50%);
  transition: all 0.2s ${easings.appleOut};
`

export const LineTop = styled(Line)`
  margin-top: -${0.6 + 0.3}rem;
`

export const LineBottom = styled(Line)`
  margin-top: ${0.6 + 0.3}rem;
`
