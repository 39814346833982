import React from "react"
import useMobile from "../../lib/useMobile"
import FooterMobile from "./mobile"
import FooterDesktop from "./desktop"

export default function Footer({ ...props }) {
  const [isMobile] = useMobile()

  return isMobile ? <FooterMobile {...props} /> : <FooterDesktop />
}
