import styled from "styled-components"
import { sidePadding, breakpoints } from "../Style"

export const Main = styled.main`
  padding: ${sidePadding}rem;

  @media ${breakpoints.medium} {
    padding: 0;
  }
`
