export const initialLike = {
  like: { posting: false, id: -1, shouldDislike: false, needsUpdate: false },
}

export function likeReducer(state, action) {
  switch (action.type) {
    case "LIKE_POST":
      return {
        ...state,
        posting: true,
        shouldDislike: action.payload.shouldDislike,
        id: action.payload.id,
      }
    case "LIKE_POSTED":
      return {
        ...state,
        posting: false,
      }
    case "LIKE_SHOULD_UPDATE":
      return {
        ...state,
        needsUpdate: true,
      }
    case "LIKE_HAS_UPDATED":
      return {
        ...state,
        needsUpdate: false,
      }
    case "LIKE_RESET":
      return initialLike.like

    default:
      return state
  }
}
