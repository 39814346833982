import styled from "styled-components"

export const Copyright = styled.p`
  margin: 4.3rem auto;
  color: #888888;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0.5rem;
  text-align: center;
  text-transform: uppercase;
`
