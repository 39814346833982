import styled from "styled-components"
import Icon from "../Icon"
import { colors, fontFamily, easings, breakpoints } from "../Style"

export const BtnReset = styled.button`
  display: inline-block;
  border: none;
  cursor: pointer;
  text-align: center;
  text-decoration: none;
  line-height: normal;

  &:focus {
    box-shadow: none;
    outline: none;
  }
`

export const StyledButton = styled(BtnReset)`
  position: relative;
  padding: 0.6rem ${2 - 1.6}rem 1rem 2rem;
  margin-right: 1.6rem;
  color: ${colors.complementary.blue};
  font-size: 2rem;
  font-family: ${fontFamily.primary};
  font-weight: 700;

  @media ${breakpoints.medium} {
    min-width: 20rem;
    padding: 1.5rem ${3 - 1.6}rem 1.6rem 3rem;
    font-size: 2.4rem;
  }

  & {
    &,
    &:before {
      box-shadow: inset 0 0 transparent;
      transition-property: background-color, color, box-shadow;
      transition-duration: 0.1s;
      transition-timing-function: ${easings.appleOut};
      background-color: ${colors.yellow};
    }

    &::after {
      border-color: ${colors.yellow} transparent transparent ${colors.yellow};
    }
  }

  &:hover {
    color: #424b52;

    &,
    &:before {
      background-color: #faca48;
    }

    &::after {
      border-color: #faca48 transparent transparent #faca48;
    }
  }

  &:focus {
    color: ${colors.dark};

    &,
    &:before {
      background-color: ${colors.yellow};
      box-shadow: inset 0 0.2rem #b58b2a;
    }

    &::after {
      border-color: ${colors.yellow} transparent transparent ${colors.yellow};
    }
  }

  &::before,
  &::after {
    position: absolute;
    left: 100%;
    content: "";
  }

  &:before {
    top: 0;
    width: 1.6rem;
    height: calc(100% - 1.6rem);
  }

  &::after {
    bottom: 0;
    border-width: 0.8rem;
    border-style: solid;
    content: "";
  }
`

export const StyledIcon = styled(Icon)`
  position: relative;
  top: 0.4rem;
  margin-right: ${props => (props.position === "left" ? "0.4rem" : 0)};
  margin-left: ${props => (props.position === "left" ? "-0.2rem" : "0.4rem")};
`

export const StyledSlimButton = styled(BtnReset)`
  padding: 1.1rem 1rem 1.2rem;
  color: ${props => (props.invert ? colors.yellow : colors.complementary.blue)};
  font-size: 2rem;
  font-weight: 700;
  font-family: ${fontFamily.primary};
  background-color: ${props => (props.invert ? "#666666" : colors.yellow)};

  ${StyledIcon} {
    position: relative;
    top: 0.2rem;
    margin-right: 1rem;
  }
`
