import styled from "styled-components"
import { breakpoints, tweakpoints, sidePadding } from "../../Style"

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-top: auto;

  @media ${breakpoints.medium} {
    display: block;
    text-align: center;
  }

  @media ${tweakpoints.formAction} {
    display: flex;
    text-align: left;
  }
`

export const DotsContainer = styled.div`
  @media ${breakpoints.medium} {
    margin-bottom: ${sidePadding}rem;
  }

  @media ${tweakpoints.formAction} {
    margin-bottom: 0;
  }
`
