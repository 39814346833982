export const initialMenu = {
  menu: { isOpen: false },
}

export function menuReducer(state, action) {
  switch (action.type) {
    case "MENU_OPEN":
      return {
        ...state,
        isOpen: true,
      }
    case "MENU_CLOSE":
      return {
        ...state,
        isOpen: false,
      }
    case "MENU_TOGGLE":
      return {
        ...state,
        isOpen: !state.isOpen,
      }
    default:
      return state
  }
}
