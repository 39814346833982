import styled from "styled-components"
import { colors, breakpoints } from "./Style"

export const H1 = styled.h1`
  color: ${colors.yellow};
  font-size: 6.4rem;
  font-weight: 700;
  line-height: 7.2rem;
`

export const H2 = styled.h2`
  color: ${colors.yellow};
  font-size: 4.8rem;
  font-weight: 700;
  line-height: 5.4rem;
`

export const H3 = styled.h3`
  color: ${colors.yellow};
  font-size: 2rem;
  font-weight: 700;
  letter-spacing: 0.417rem;
  line-height: 3.6rem;
  text-transform: uppercase;

  @media ${breakpoints.medium} {
    font-size: 2.4rem;
    letter-spacing: 0.5rem;
  }
`

export const Preamble = styled.p`
  font-size: 1.8rem;
  font-weight: 600;
  line-height: 1.25;

  @media ${breakpoints.medium} {
    font-size: 2rem;
    font-weight: 700;
    line-height: 1.3;
  }
`

export const Text = styled.p`
  font-size: 1.8rem;
  font-weight: 500;
  line-height: 1.38;
`
