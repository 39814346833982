import styled from "styled-components"
import Icon from "../Icon"
import { sidePadding, breakpoints } from "../Style"

export const Nav = styled.nav`
  overflow: hidden;
`

export const List = styled.ul`
  display: flex;
  justify-content: center;
  padding: 1.7rem ${sidePadding}rem 3.5rem;
  margin: 0 -1.5rem;

  @media ${breakpoints.large} {
    margin: 0 -3.5rem;
  }
`

export const Item = styled.li`
  padding: 0 1.5rem;

  @media ${breakpoints.large} {
    padding: 0 3.5rem;
  }
  
  button {
    font-family: inherit;
  }
`

export const StyledIcon = styled(Icon)`
  position: absolute;
  top: 0.3rem;
  left: -${2 + 0.2}rem;
  width: 2rem;

  @media ${breakpoints.large} {
    top: 0;
    left: -${2.5 + 0.5}rem;
    width: 2.5rem;
  }
`
