import React, { Suspense } from "react"
import initSelect from "../../lib/initSelect"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import Footer from "../footer"
import Header from "../header"
import { Main } from "./Style"
import CTA from "../cta"

const About = React.lazy(() => import("../about/"))

const Layout = ({ children, location, pageContext }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  initSelect(pageContext)

  return (
    <>
      <Header siteTitle={data.site.siteMetadata.title} location={location} />
      <Main>{children}</Main>
      <Footer location={location} />
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
