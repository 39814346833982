import styled from "styled-components"
import { easings, colors, resetInput } from "../Style"

export const DotWrapper = styled.label`
  position: relative;
  z-index: 0;
  display: inline-block;

  &:not(:last-child) {
    margin-right: 1.4rem;
  }
`

export const Input = styled.input`
  ${resetInput()}
`

export const StyleDot = styled.span`
  position: relative;
  z-index: 0;
  display: block;
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 50%;
  background-color: ${props => (props.active ? colors.yellow : colors.white)};
  transition: background-color 0.1s ${easings.appleOut};

  &:hover {
    background-color: #cccccc;
  }

  &::after {
    position: absolute;
    z-index: -1;
    display: block;
    top: -0.5rem;
    right: -0.5rem;
    bottom: -0.5rem;
    left: -0.5rem;
    border-radius: 50%;
    background-color: ${colors.yellow};
    opacity: 0.2;
    content: "";
    transform: scale(0);
    transition: transform 0.1s ${easings.appleOut};
  }

  ${Input}:focus ~ &::after {
    transform: scale(1);
  }

  ${Input}:checked ~ & {
    background-color: ${colors.yellow};
  }
`
