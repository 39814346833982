const inputValues = {
  identifier: "",
  file: "",
  image: "",
  model: "",
  term: "",
  year: "",
  description: "",
  machineName: "",
  city: "",
  accept: false,
}

export const initialEdit = {
  edit: {
    submit: false,
    errors: {},
    form: null,
    values: {
      ...inputValues,
    },
  },
}

export function editReducer(state, action) {
  switch (action.type) {
    case "EDIT_RESET":
      return {
        ...state,
        submit: false,
        errors: {},
        values: {
          ...state.values,
          ...inputValues,
        },
      }

    case "EDIT_SET":
      return {
        ...state,
        ...action.payload,
      }

    case "EDIT_UPDATE":
      return {
        ...state,
        values: {
          ...state.values,
          ...action.payload,
        },
      }

    case "EDIT_SUBMIT":
      return {
        ...state,
        errors: {},
        submit: true,
      }

    case "EDIT_ERROR_SET":
      return {
        ...state,
        errors: action.payload,
        submit: false,
        values: {
          ...state.values,
          file: "",
        },
      }

    case "EDIT_ERROR_UPDATE":
      return {
        ...state,
        errors: action.payload,
      }

    default:
      return state
  }
}
