import React from "react"
import PropTypes from "prop-types"
import { DotWrapper, Input, StyleDot } from "./Style"

export default function Dot({
  name,
  label,
  title,
  isChecked = false,
  ...props
}) {
  return (
    <DotWrapper>
      <Input
        checked={isChecked}
        {...(isChecked ? { checked: true } : { checked: false })}
        type="radio"
        name={name}
        data-label={label}
        title={title}
        {...props}
        disabled
      />
      <StyleDot />
    </DotWrapper>
  )
}

Dot.propTypes = {
  name: PropTypes.string.isRequired,
}
